<template>
  <r-page>
    <template #page-header>
      <h2 class="page-title">Path Count</h2>
    </template>
    <template #action-bar>
      <div class="level">
        <div class="level-left"></div>
        <div class="level-right">
          <b-input
            icon-right="magnify"
            rounded
            v-model="search"
            placeholder="Search"
            @keydown.native.enter="searchIconClick(search)"
            icon-clickable
            @icon-right-click="searchIconClick(search)"
            icon-right-clickable
            :loading="isLoading"
          >
            Search
          </b-input>
        </div>
      </div>
    </template>
    <template #page-content>
      <b-table
        :data="data ? data.data : []"
        :loading="isLoading"
        paginated
        backend-pagination
        :total="data && data.meta ? data.meta.total : {}"
        :per-page="perPage"
        @page-change="onPageChange"
        aria-next-label="Next page"
        aria-previous-label="Previous page"
        aria-page-label="Page"
        aria-current-label="Current page"
        :current-page.sync="page"
        hoverable
        backend-sorting
        @sort="onSort"
        class="table-timeoff-type table-request-personal"
      >
        <b-table-column field="no" label="No" v-slot="props" sortable>
          {{ props.index + data.meta.from }}
        </b-table-column>
        <b-table-column
          field="full_paths"
          label="Paths"
          v-slot="props"
          sortable
        >
          {{ props.row.fullPath }}
        </b-table-column>
        <b-table-column
          field="path_count"
          label="Path Count"
          v-slot="props"
          sortable
        >
          {{ props.row.pathCount }}
        </b-table-column>
      </b-table>
    </template>
  </r-page>
</template>
<script>
import ApiService from '@/services/common/api.service'

export default {
  data() {
    return {
      data: {
        meta: {},
        data: [],
      },

      isLoading: false,
      page: 1,
      perPage: 10,
      lastPage: 1,
      total: 0,
      orderBy: 'path_count',
      orderDirection: 'desc',
      search: '',
    }
  },

  methods: {
    async loadAsyncData(search, orderBy, orderDirection, page, perPage) {
      await ApiService.get(
        `/auth/paths?&search=${search}&order_by=${orderBy}&order_direction=${orderDirection}&page=${page}&per_page=${perPage}`
      ).then((response) => {
        this.data.data = response.data.data
        this.data.meta = response.data.meta
        let currentTotal = this.data.meta.total
        if (this.data.meta.total / this.perPage > 1000) {
          currentTotal = this.perPage * 1000
        }
        this.total = currentTotal
        this.isLoading = false
      })
    },

    searchIconClick(search) {
      this.isLoading = true
      this.loadAsyncData(
        search,
        this.orderBy,
        this.orderDirection,
        this.page,
        this.perPage
      )
      this.isLoading = false
    },

    onPageChange(page) {
      this.page = page
      this.loadAsyncData(
        this.search,
        this.orderBy,
        this.orderDirection,
        this.page,
        this.perPage
      )
    },

    onSort(field, order) {
      this.orderBy = field
      this.orderDirection = order
      this.page = 1
      this.perPage = 10
      this.data.data = []
      this.isLoading = true
      this.loadAsyncData(
        this.search,
        this.orderBy,
        this.orderDirection,
        this.page,
        this.perPage
      )
    },
  },

  mounted() {
    this.loadAsyncData(
      this.search,
      this.orderBy,
      this.orderDirection,
      this.page,
      this.perPage
    )
  },
}
</script>
